import React, { useState, useEffect, useRef } from 'react';
import { AlertTriangle, ArrowUpRight, Banknote, PlusCircle, Mail } from 'lucide-react';

const Blotter = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  // Mock data - replace with actual data fetching in your implementation
  const events = [
    { date: '2023-07-30', type: 'hack', protocol: 'Curve Finance', description: 'Multiple stablepools exploited due to a reentrancy vulnerability', amount: '47000000', source: 'https://www.coindesk.com/markets/2023/07/30/curve-finance-exploited-for-over-47m-across-multiple-pools/' },
    { date: '2023-04-15', type: 'hack', protocol: 'Yearn Finance', description: 'Exploited misconfigured yUSDT vault leading to loss of funds', amount: '11600000', source: 'https://www.coindesk.com/tech/2023/04/15/yearn-finance-exploited-for-116m-due-to-misconfigured-ysdt-vault/' },
    { date: '2023-03-13', type: 'hack', protocol: 'Euler Finance', description: 'Flash loan attack exploited vulnerability in smart contracts', amount: '197000000', source: 'https://www.coindesk.com/tech/2023/03/13/euler-finance-hacked-for-over-195m-in-flash-loan-exploit/' },
    { date: '2023-02-02', type: 'enforcement', protocol: 'Bitzlato', description: 'Arrest of founder for operating unlicensed money-transmitting business', amount: 'N/A', source: 'https://www.justice.gov/opa/pr/department-justice-announces-arrest-bitcoin-exchange-founder-charges-illegal-cryptocurrency' },
    { date: '2023-01-01', type: 'enforcement', protocol: 'Mango Markets', description: 'Arrest of Avraham Eisenberg for manipulating Mango Markets', amount: 'N/A', source: 'https://www.justice.gov/usao-sdny/pr/us-attorney-announces-charges-against-avraham-eisenberg-market-manipulation-mango-markets' },
    { date: '2022-11-02', type: 'hack', protocol: 'Deri Protocol', description: 'Price manipulation exploit led to draining of funds', amount: '80000', source: 'https://www.coindesk.com/tech/2022/11/02/defi-platform-deri-protocol-exploited-in-price-manipulation-attack/' },
    { date: '2022-10-11', type: 'hack', protocol: 'Mango Markets', description: 'Manipulated MNGO token price to drain funds via over-collateralized loans', amount: '114000000', source: 'https://cointelegraph.com/news/defi-protocol-mango-markets-loses-over-100m-in-exploit' },
    { date: '2022-08-08', type: 'enforcement', protocol: 'Tornado Cash', description: 'U.S. Treasury sanctioned Tornado Cash for facilitating money laundering', amount: 'N/A', source: 'https://home.treasury.gov/news/press-releases/jy0916' },
    { date: '2022-08-01', type: 'hack', protocol: 'Nomad Bridge', description: 'Faulty contract allowed repeated withdrawals in copy-paste exploit', amount: '190000000', source: 'https://www.coindesk.com/markets/2022/08/02/nomad-bridge-hacked-for-190m-in-latest-exploit-to-hit-cross-chain-bridges/' },
    { date: '2022-06-24', type: 'hack', protocol: 'Harmony Horizon Bridge', description: 'Exploited vulnerability in cross-chain bridge to steal funds', amount: '100000000', source: 'https://www.coindesk.com/markets/2022/06/24/harmony-blockchain-horizon-bridge-hacked-for-100m/' },
    { date: '2022-05-08', type: 'hack', protocol: 'Rari Capital', description: 'Exploited reentrancy vulnerability in Fuse lending pools', amount: '80000000', source: 'https://www.coindesk.com/tech/2022/05/08/rari-capitals-fuse-platform-exploited-for-80m/' },
    { date: '2022-04-17', type: 'hack', protocol: 'Beanstalk Farms', description: 'Flash loan attack exploiting governance mechanism to drain funds', amount: '182000000', source: 'https://www.coindesk.com/markets/2022/04/18/beanstalk-stablecoin-protocol-suffers-182m-exploit/' },
    { date: '2022-03-23', type: 'hack', protocol: 'Ronin Network', description: 'Compromised private keys to forge fake withdrawals from bridge', amount: '625000000', source: 'https://www.reuters.com/technology/hackers-steal-over-600-million-axie-infinity-blockchain-game-2022-03-29/' },
    { date: '2022-02-02', type: 'hack', protocol: 'Wormhole Bridge', description: 'Exploit in the bridge between Solana and Ethereum led to loss of funds', amount: '325000000', source: 'https://www.theverge.com/2022/2/3/22915613/wormhole-cryptocurrency-platform-hack-320-million-stolen' },
    { date: '2021-12-02', type: 'hack', protocol: 'BadgerDAO', description: 'Front-end exploit with malicious scripts prompting unwanted approvals', amount: '120000000', source: 'https://www.coindesk.com/markets/2021/12/02/defi-platform-badgerdao-halted-after-reportedly-suffering-120m-exploit/' },
    { date: '2021-10-29', type: 'rug', protocol: 'AnubisDAO', description: 'Anonymous team transferred funds out, disappearing with investments', amount: '60000000', source: 'https://www.coindesk.com/markets/2021/10/29/60m-anubisdao-rug-pull-highlights-dangers-of-anon-devs-in-defi/' },
    { date: '2021-09-20', type: 'hack', protocol: 'pNetwork', description: 'Attacker exploited a bug to steal BTC from Binance Smart Chain', amount: '12700000', source: 'https://www.theblockcrypto.com/post/117490/defi-cross-chain-protocol-pnetwork-loses-12m-in-token-exploit' },
    { date: '2021-08-10', type: 'hack', protocol: 'Poly Network', description: 'Exploited cross-chain vulnerability to transfer assets illicitly', amount: '610000000', source: 'https://www.theverge.com/2021/8/10/22618922/poly-network-cryptocurrency-ethereum-binance-hack-defi-blockchain' },
    { date: '2021-07-10', type: 'hack', protocol: 'ChainSwap', description: 'Exploited vulnerability in smart contract affecting multiple tokens', amount: '8000000', source: 'https://www.coindesk.com/markets/2021/07/10/defi-platform-chainswap-loses-8m-in-second-exploit-this-month/' },
    { date: '2021-05-20', type: 'hack', protocol: 'PancakeBunny', description: 'Flash loan attack manipulated BUNNY token price for profit', amount: '45000000', source: 'https://cointelegraph.com/news/flash-loan-attack-costs-binance-smart-chain-based-pancakebunny-45m' },
    { date: '2021-04-28', type: 'rug', protocol: 'Uranium Finance', description: 'Exploit during migration suspected to be an insider rug pull', amount: '50000000', source: 'https://www.coindesk.com/markets/2021/04/28/defi-project-uranium-finance-loses-50m-in-exploit/' },
    { date: '2021-03-05', type: 'rug', protocol: 'Meerkat Finance', description: 'Developers drained funds by altering the smart contract', amount: '31000000', source: 'https://cointelegraph.com/news/binance-smart-chain-project-meerkat-finance-drained-of-31-million-in-suspected-rug-pull' },
    { date: '2020-12-28', type: 'hack', protocol: 'Cover Protocol', description: 'Infinite minting exploit allowed creation of unlimited COVER tokens', amount: '4000000', source: 'https://www.coindesk.com/markets/2020/12/28/defi-cover-protocol-hacked-exploiter-mints-40-quadrillion-tokens/' },
    { date: '2020-11-21', type: 'rug', protocol: 'Compounder Finance', description: 'Developers deployed malicious contracts to steal user funds', amount: '10800000', source: 'https://cointelegraph.com/news/defi-protocol-compounder-finance-exit-scams-with-10-8-million-in-funds' },
    { date: '2020-11-21', type: 'hack', protocol: 'Pickle Finance', description: 'Exploited vulnerability in smart contracts to steal from pDAI Jar', amount: '20000000', source: 'https://www.coindesk.com/markets/2020/11/21/pickle-finance-exploited-for-19-7m-in-dai-just-weeks-after-launch/' },
    { date: '2020-09-13', type: 'hack', protocol: 'Soft Yearn', description: 'Exploited vulnerability to mint unlimited tokens', amount: '1000000', source: 'https://www.coindesk.com/markets/2020/09/13/defi-project-soft-yearn-suffers-1m-exploit-hours-after-launch/' },
    { date: '2020-04-19', type: 'hack', protocol: 'Lendf.me', description: 'Reentrancy attack exploiting ERC-777 token vulnerability', amount: '25000000', source: 'https://www.theblock.co/post/63084/dforce-protocol-loses-25-million-in-attack' },
    { date: '2020-02-14', type: 'hack', protocol: 'bZx', description: 'Flash loan attack manipulating price oracles to profit from leveraged trades', amount: '350000', source: 'https://www.coindesk.com/markets/2020/02/18/defi-platform-bzx-exploited-again-as-flash-loans-proliferate/' }
    // Add more mock data as needed
  ];

  const filteredEvents = events.filter(event => 
    event.protocol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedRowIndex(prev => Math.min(prev + 1, filteredEvents.length - 1));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedRowIndex(prev => Math.max(prev - 1, 0));
      } else if (e.key === 'Enter') {
        // TODO: Implement row expansion functionality
        console.log('Enter pressed on row:', filteredEvents[selectedRowIndex]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [filteredEvents, selectedRowIndex]);

  useEffect(() => {
    if (tableRef.current && tableContainerRef.current) {
      const selectedRow = tableRef.current.querySelector(`tr:nth-child(${selectedRowIndex + 1})`);
      if (selectedRow) {
        const container = tableContainerRef.current;
        const rowTop = selectedRow.offsetTop;
        const rowBottom = rowTop + selectedRow.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;

        if (rowTop < containerTop) {
          container.scrollTop = rowTop;
        } else if (rowBottom > containerBottom) {
          container.scrollTop = rowBottom - container.clientHeight;
        }
      }
    }
  }, [selectedRowIndex]);

  const getEventTypeIcon = (type) => {
    switch (type) {
      case 'hack':
        return <AlertTriangle size={16} />;
      case 'rug':
        return <ArrowUpRight size={16} />;
      case 'enforcement':
        return <Banknote size={16} />;
      default:
        return null;
    }
  };

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const handleSubmitEvent = () => {
    console.log('Submit an Event clicked');
    // TODO: Implement submit event functionality
  };

  const handleContact = () => {
    console.log('Contact clicked');
    // TODO: Implement contact functionality
  };

  return (
    <div className="blotter-container">
      <div className="blotter-content">
        <h1 className="blotter-title">DefiDeputy Blotter</h1>
        
        <div className="search-container">
          <p className="search-prompt">
            {'>'} Protocol search: 
            <input 
              type="text" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <span className="cursor">_</span>
          </p>
        </div>
        
        <div ref={tableContainerRef} className="table-container">
          <table ref={tableRef} className="event-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Protocol</th>
                <th>Description</th>
                <th>Amount ($)</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event, index) => (
                <tr 
                  key={index} 
                  onClick={() => handleRowClick(index)}
                  className={index === selectedRowIndex ? 'selected-row' : ''}
                >
                  <td>{event.date}</td>
                  <td className={`event-type ${event.type}`}>
                    <div className="event-type-content">
                      {getEventTypeIcon(event.type)}
                      <span>{event.type}</span>
                    </div>
                  </td>
                  <td>{event.protocol}</td>
                  <td>{event.description}</td>
                  <td className="amount">{parseInt(event.amount).toLocaleString()}</td>
                  <td>
                    <a href={event.source} target="_blank" rel="noopener noreferrer" className="source-link">Link</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <footer className="blotter-footer">
        <button onClick={handleSubmitEvent} className="footer-button submit-button">
          <PlusCircle size={16} />
          Submit an Event
        </button>
        <button onClick={handleContact} className="footer-button contact-button">
          <Mail size={16} />
          Contact
        </button>
      </footer>
    </div>
  );
};

export default Blotter;